import React from "react";

const Banner = (props) => {
    const { children, backgroundImage } = props;
    
    return (
        <section className="banner" style={{
            background: backgroundImage ? 
            `
                linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage}) center/cover no-repeat
            ` : null
        }}>
            {children}
        </section>
    )
}

export default Banner;